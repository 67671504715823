import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

import * as amplitude from '@amplitude/analytics-browser';
// amplitude.init('3c0ec63d0cc1e5ac58efe9aaa3c37d9b', undefined, 
amplitude.init('c59e7dfed8df1e5dea277399b25b2a7e', undefined, 


{ defaultTracking: { sessions: true, pageViews: true, formInteractions: true, fileDownloads: true }});


const app = (
        <BrowserRouter>
            <App />
        </BrowserRouter>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();